.container_ads{
    display: flex;
    flex-direction: row;

}
.constinerinn{
    display: flex;
    flex-direction: column;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    width:100%;
    margin-bottom: 10px;  
}
.image_ad{
    width:20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.middle_ad{
    width:60%;
    display: flex;
    padding: 20px;
    flex-direction: column;
}
.button_ad{
    width:20%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.imageinside{
    width:100px;
    margin-top: 10px;
    display: flex;
    margin-inline: auto;
    height: 100px;

}


.containerqrc {
    /* padding: 20px; */
  }
  
  .contqrc {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .contqrcsecond {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .stepbutton {
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;
    background: rgb(64, 64, 64);
    color: white;
    margin-right: 10px;
  }
  
  .steplabel {
    font-size: 16px;
  }
  
  .inputfield {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-block: 10px;
    width: 100%;
  }
  
  .radioContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }
  
  .radioLabel {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .radioInput {
    margin-right: 5px;
  }
  
  .qrcode {
    display: flex;
    justify-content: center;
  }
  .labelpattern{
    font-size: 15px;
    padding: 5px;
    width:120px;
  }
  .labelpattern1{
    font-size: 15px;
    padding: 5px;
    width:115px;
  }

  .patterninput{
    font-size: 15px;
    padding: 5px;
    width:150px;

  }
  .patterninput1{
    font-size: 15px;
    padding: 5px;
  }

  .section_a{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.properties{
    height:55px;
}

.containerproperties {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
  
  .subcontainer{
    width: 33%;
    padding: 15px;
  }
  
  @media (max-width: 800px) {
    .containerproperties {
      flex-direction: column; 
    }
  
    .subcontainer {
      width: 100%;
      margin-bottom: 10px;
    }
  }
.containerproperties1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
  
  .subcontainer1{
    width: 50%;
    padding: 15px;
  }
  
  @media (max-width: 800px) {
    .containerproperties1 {
      flex-direction: column; 
    }
  
    .subcontainer1 {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
