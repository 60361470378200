.containergrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.boxgrid {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-width>div {
  width: 30%;
}

.modal-contact-details>div {
  width: 32%;
}

.modal-condition-details>div {
  width: 32%;
}
.modal-width-add-details >div{
width: 100%;
}
h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

.audio-player-progress {
  width: 100%;
  margin: 20px 0;
}

.audio-player-progress input[type='range'] {
  width: 100%;
  -webkit-appearance: none;
  height: 2px;
  border-radius: 2px;
  background: #d3d3d3;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
  border-radius: 50px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}
.react-mic{
  border-radius: 4px;
  height: 50px;
  display: none;
}

#orderLoading {
  animation: rotation 1s infinite linear;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid;
  border-style: outset;
  color: #060404;

}
.margin-modal>div>div{
  margin-top: 100px;
}

@media screen and (min-width: 768px) {
  .containergrid {
    grid-template-columns: repeat(4, 1fr);
  }
}