@media (max-width: 768px) {
  .mobile-margin-top {
    margin-top: -100px;
  }
}

.modal-scanned-details>div{
  width: 100%;
}

.custom-modal .modal-dialog {
  height: 80vh; 
}

.custom-modal .modal-content {
  height: 100%;
}

.custom-modal-body {
  overflow-y: auto;
  height: calc(100% - 56px);
  padding: 20px;
}
