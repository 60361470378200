.container_enter{
    display: flex;
    flex-direction: row;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.container_a{
display: flex;
width: 100%;
flex-direction: row;
align-items: center;

}

.image_enter{
    width: 150px;
    height: 150px;
}
.containergrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .boxgrid {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-width>div {
    width: 30%;
  }
  
  .modal-contact-details>div {
    width: 32%;
  }
  
  .modal-condition-details>div {
    width: 32%;
  }
  
  @media screen and (min-width: 768px) {
    .containergrid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .heading{
    color: gray;
  }
  .head{
    color: black;
  }
  .boxcard{
    transition: transform 0.3s, box-shadow 0.3s;
    overflow-x: hidden;
}
  .container_enter:hover{
    transform: scale(1.01);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
  }

  .card-body{
    padding: 10px !important;
  }
  
  .buttonsection{
display: flex;
flex-direction: column;
justify-content: space-around;
margin-top: auto;

  }

  .buttonsend{
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: orange;
    font-weight: 500;
    background-color: white;
    border: 1px solid orange;
    border-radius: 5px;
    font-size: 14px;
    transition: color 0.5s ease, background-color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;

  }
  .constbutton{
    justify-content: center;
    display: flex;
    align-items: center;
    color: black;
    font-weight: 500;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 14px;

  }

  .container_enter:hover .buttonsend {
    color: white;
    background-color: orange; 
  }