
.crop-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin-inline: auto;
}

.crop-container .reactEasyCrop_CropArea {
  border-radius: 50%;
}

.crop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

