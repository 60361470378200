.cont_head {
    display: flex;
    flex-direction: row;
    gap: 2%;
    width: 100%;
  }
  
  .sub_cont {
    display: flex;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-inline: 3px;
    transition: width 0.3s ease;
  }
  .sub_cont1 {
    display: flex;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    width: 50%;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-inline: 3px;
    transition: width 0.3s ease;
  }
  
  .sub_cont.expanded {
    width: 49%;
  }
  
  .input_sub {
    display: flex;
    width: 100%;
    padding-inline: 3px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
  }

  .second_cont {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .sub_second_cont {
    display: flex;
    width: 50%;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .sub_second_cont1 {
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .image_second {
    width: 100px;
    height: 100px;
    display: flex;
  }
  
  .dropdown1 {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 3px;
  }
  
  .menu_icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  
  .menu {
    position: absolute;
    top: 24px;
    right: 0;
    background-color: white;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }
  
  .menu button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  
  .menu button:hover {
    background-color: #f0f0f0;
  }
  
  .inputsecond{
    display: flex;
    width: 100%;
  }
  .inputsecondThird{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inputfield{
    display: flex;
    width: 80%;
    margin-inline: auto;
  }
  .inputfieldthird{
    display: flex;
    width: 90%;
    margin-inline: auto;
  }

  .checkboxes{
    display:flex;
    flex-direction: row;
    width: 100%;
  }

  .checkbox_a{
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    font-size: 16px;
    width:40%;
    justify-content: center;
  }

  .checkbox_b{
    display: flex;
    width:60%;
    justify-content: center;
  }

  .third_Cont{
    display: flex;
    /* border:2px solid red; */
    width: 90%;
    margin-inline: auto;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .promotion{
    padding-block: 4px;
    margin-left: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid #d3d3d3;
  }

  .addons{
    display: flex;
    width: 90%;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    margin-inline: auto;
  }
  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .list_sub {
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    margin: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding-inline: 10px;
    transition: all 0.3s ease-in-out;
  }
  .list_sub2 {
    border-bottom: 2px solid #d3d3d3;
    /* border-radius: 5px; */
    margin: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding-inline: 10px;
    transition: all 0.3s ease-in-out;
  }
  .list_sub1 {
    margin: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 110px;
    display: flex;
    padding-inline: 10px;
  }
  
  .list_sub:hover {
    /* border-color: #007bff; */
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
.inputfieldlist{
    display: flex;
    width: 100%;
    border:1px solid #d3d3d3;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 14px;
    padding-left: 5px;
    margin-inline: auto;
  }

  .inputfieldlistprice{
    display: flex;
    width: 150px;
    margin-left: 20px;
    border:1px solid #d3d3d3;
    border-radius: 5px;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    .checkboxes {
      flex-direction: column;
    }
    .checkbox_a{
        width: 90%;
    }
    .checkbox_b{
        width: 90%;
        margin-inline: auto;
        margin-bottom: 10px;
    }
  }