.qr-code-li {
    display: none; 
  }
  
  @media (min-width: 600px) {
    .largescreen {
      display: flex;  
      flex-direction: column;
      margin-left: 15px;
      background: #fff;
      border-radius: 10px;
      position: absolute;
      z-index: 9999;
    }
  }
.qr-code-li1 {
    display: flex; 
    margin-left: 15px;
                          background: #fff;
                          border-radius: 10px;
                          justify-content:center;
                          display:flex;
                          
                          flex-direction:column;
  }
  
  @media (min-width: 600px) {
    .largescreen1 {
     display: none;
   
    }
  }